import Vue from 'vue'
import store from '@/store'
import { Notification  } from 'element-ui'
import router from '../router';
import { TokenClient } from '@/components/tokenclient/TokenClient'
import axios from 'axios'
import { ApiClient } from '@/components/tokenclient/ApiClient'
// import basePath from '../../public/services.json'
// var loadUrl = basePath.Authorization + '/api/Message/GetHomeMessage/1'
var loadUrl
var ws;
var tt;
var lockReconnect = false;//避免重复连接
var notificationInstance = null
var isScoketFalse = false
var serverTimeoutObj = null
var webUrl
var websocket
ApiClient.Get(
  '/services.json',
  false,
  [],
  null,
  function (res) {
   var data = JSON.parse(res)
    webUrl =  data.MsgWss
    loadUrl = data.Authorization + '/api/Message/GetHomeMessage/1'
     websocket = {
      Init: function () {
          var _this = this
          if ("WebSocket" in window) {
              ws = new WebSocket(webUrl);
          } else if ("MozWebSocket" in window) {
              ws = new MozWebSocket(webUrl);
          } else {
              console.log("您的浏览器不支持 WebSocket!");
              return;
          }
          ws.onmessage = async function (e) {
            if (JSON.parse(e.data).Code == '401') {
              userToken = await Vue.prototype.TokenClient.AccessToken()
            } else {
              messageHandle(e)
            }
          }
          ws.onclose = function (e) {
            var _this = this
              console.log('断开链接')
              clearInterval(serverTimeoutObj)
              //重连
              // reconnect(_this.url);
          }
          ws.onopen = async function () {
              clearInterval(serverTimeoutObj)
              ws.send(JSON.stringify({
                token: await Vue.prototype.TokenClient.AccessToken()
              }));
              serverTimeoutObj = setInterval(()=>{
                var ping = {
                  "msgtype": "0"
                };
                ws.send(JSON.stringify(ping));
              },20000);
          }
          ws.onerror = function (e) {
              console.log(e)
              console.log("数据传输发生错误");
              setTimeout(()=>{
                reconnect(webUrl)
              },30000)
              
          }
      },
      webClosed: function () {
        isScoketFalse = true
        ws.onclose = function () {
          var _this = this
            console.log('自动退出断开链接')
            clearInterval(serverTimeoutObj)
        }
      }
  }
  
  document.addEventListener('visibilitychange', function() {
    if (document.visibilityState === 'hidden') {
        console.log('页签不可见，关闭WebSocket连接');
        // ws.onclose
        if (websocket.webClosed.length != 0) {
          websocket.webClosed()
        }
    } else if (document.visibilityState === 'visible') {
        console.log('页签变为可见，重新建立WebSocket连接');
        websocket.Init(webUrl);
    }
  });
  },
)

// 语音播放
// var isSpeaking = false
//   function  messageAudio() {
//       if (!isSpeaking) {
//           var audio
//           audio = "https://oss.qyyl365.com/audio/order.mp3";
//           audio = new Audio(audio); 
//           isSpeaking = true
//           // 循环播放，播放结束继续播放
//           $(audio).unbind("ended").bind("ended", ()=>{
//               audio.pause();
//               isSpeaking = false
//           })
//           audio.play();
//       } 
//       // // 文字转语音
//       // if ('speechSynthesis' in window) {
//       //     var msg = new SpeechSynthesisUtterance('撒娇打算机读卡');
//       //     msg.lang = 'zh-CN'; // 设置语言为中文
//       //     msg.onstart = event => console.log('语音开始播放'); // 开始事件处理函数
//       //     msg.onend = event => console.log('语音播放结束'); // 结束事件处理函数
//       //     msg.onerror = event => console.error('语音播放出错', event.error); // 错误事件处理函数
//       //     speechSynthesis.speak(msg);
//       // } else {
//       //     alert('当前浏览器不支持语音合成功能');
//       // }
//   }
// export default websocketClose;
//根据消息标识做不同的处理
function messageHandle(e) {
    var _this = this
    getMessage()
    let data = JSON.parse(e.data);
    let messageContent = '';
    if (data.Data) {
      if (data.Data.SubType === 24) {
        messageContent = '查看';
      } else if (data.Data.SubType === 25) {
        messageContent = '去处理';
      } else if (data.Data.SubType === 12){
        messageContent = '去审核';
      } else if (data.Data.SubType === 13){
        messageContent = '去付款';
      }  else if (data.Data.SubType == 27){
        messageContent = '去审核';
      }  
      else if (data.Data.SubType == 28){
        messageContent = '去查看';
      }else {
        messageContent = '';
      }
      notificationInstance = Notification ({
        title: data.Data.Title,
        dangerouslyUseHTMLString: true,
        duration: 0,
        position: 'bottom-right',
        message:'<div>'+ data.Data.Content +  '<span style="color: #3d5cff; cursor: pointer;">' +  messageContent + '</span></div>',
        onClick() {
          notifyClick(data.Data.Type, data.Data.SubType);
        },
      });
    }
}
function notifyClick(type, SubType) {
    if (SubType == 24) {
      router.push({
        path: '/interrogation/referralAndmedical',
        query: {
          typeCom: 0,
          msgIndex: type,
        }
      })
      notificationInstance.close();
    } else if (SubType == 25) {
      router.push({
        path: '/interrogation/referralAndmedical',
        query: {
          typeCom: 0,
          msgIndex: type,
        }
      })
      notificationInstance.close();
    } else if (SubType == 12)  {
    //  let url = router.resolve({
    //     path: '/interrogation/PremangePatientList',
    //   })
      
    //   window.open(url.href, "_blank");
      router.push({
        path: '/interrogation/PremangePatientList'
      })
      notificationInstance.close();
    }else if (SubType == 27)  {
      // let url = router.resolve({
      //    path: '/drugInterrogation/drugPremangePatientList',
      //  })
      //  window.open(url.href, "_blank");
       router.push({
        path: '/drugInterrogation/drugPremangePatientList'
      })
       notificationInstance.close();
     }
     else if (SubType == 28)  {
      // let url = router.resolve({
      //    path: '/drugInterrogation/drugPremangePatientList',
      //  })
      //  window.open(url.href, "_blank");
       router.push({
        path: '/interrogation/PremangePatientList'
      })
       notificationInstance.close();
     }
    else if (SubType == 13)  {
      // let url = router.resolve({
      //    path: '/interrogation/orderList',
      //  })
      //  window.open(url.href, "_blank");
       router.push({
        // path: '/interrogation/orderList'
        name: "orderList",
      })
       notificationInstance.close();
     } else {
      router.push({
        path: '/messageIndex?typeCom=0',
        query: {
          typeCom: 0
        }
      })
      notificationInstance.close();
     }
  }
function reconnect(sname) {
    if (lockReconnect && !isScoketFalse) {
        return;
    };
    lockReconnect = true;
    //没连接上会一直重连，设置延迟避免请求过多
    tt && clearTimeout(tt);
    tt = setTimeout(function () {
        websocket.Init(sname);
        lockReconnect = false;
    }, 4000);
}
async function getMessage () {
  axios({
    method: "GET",
    url: loadUrl,
    headers: { "Content-Type": "multipart/form-data", Authorization: "Bearer" + " " + await Vue.prototype.TokenClient.AccessToken() },
  })
  .then((res) => {
    let readNumber = 0
    for (let i=0; i<res.data.data.length; i++) {
      readNumber = readNumber + res.data.data[i].unreadCount
    }
    if (readNumber > 0) {
      store.commit('messageData', true)
    } else {
      store.commit('messageData', false)
    }
  })
  .catch((err) => {
    
  });
}

//心跳检测
// var heartCheck = {
//     start: function () {
//         var ping = {
//           "msgtype": "0"
//         };
//         ws.send(JSON.stringify(ping));
//         //这里发送一个心跳 发送失败从错误因素

//     }
// }
export default websocket