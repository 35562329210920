<template>
  <div class="home">
    <el-container>
      <nh-com :getSchoolName="orgimgkeyPathData2.orgimgkeyPath2"></nh-com>
      <!-- <touch></touch> -->
      <el-main style="background-color: #f0f1f5">
        <div class="banner1" style="margin-top: 30px">
          <div class="searchBox clearfix">
            <!-- <el-form :model="indexFrom" @submit.native.prevent style="width:100%"> -->
            <!-- <div class="more left">
                                <div class="more-btn left" style="cursor: pointer"></div>
                                <div class="more-hover">
                                    <el-checkbox :indeterminate="indexFrom.isIndeterminate" v-model="indexFrom.checkAll"
                                        @change="handleCheckAllChange">全选</el-checkbox>
                                    <el-checkbox-group v-model="indexFrom.checkedCrowd"
                                        @change="handleCheckedCrowdChange">
                                        <el-checkbox v-for="crowd in indexFrom.crowds" :label="crowd" :key="crowd">
                                            {{ crowd }}</el-checkbox>
                                    </el-checkbox-group>
                                </div>
                            </div> -->
            <!-- <div class="input-box left"> -->
            <div class="input-box">
              <el-input placeholder="请输入关键词..." v-model="indexFrom.input" @keyup.enter.native="SearchInputKeydown">
                <button slot="append" style="cursor: pointer" class="search-btn" type="button" @click="submitSearch()">搜索</button>
              </el-input>
            </div>
            <!-- </el-form> -->
          </div>
        </div>
        <div class="fastNavR">
          <div class="fast-nav1" style="width: 65%" v-if="GetIndexModulesT.length > 0">
            <div class="fastNavBox1">智能辨证</div>
            <ul class="clearfix">
              <li class="licolor0" v-for="(item, index) in GetIndexModulesT" :key="index" @click="gonewUrl(item)" style="cursor: pointer">
                <div class="nav-img">
                  <img :src="item.img" alt="" />
                </div>
                <p>{{ item.name }}</p>
              </li>
            </ul>
          </div>
          <div class="fast-nav2" style="width: 35%" v-if="Services.ClientId != 'MedicineDiagnosis.Web'">
            <div class="fastNavBox1">门诊</div>
            <div class="mZCenterBox">
              <div style="display: flex; align-items: center">
                <img class="mZCenterBoxImg" src="../assets/img/menzhen.png" alt="" />
                <div style="margin-left: 10px">
                  <div style="font-size: 16px; color: #333333; font-weight: bold">门诊功能</div>
                  <div style="font-size: 14px; color: #333333; opacity: 0.7; margin-top: 7px">特色门诊功能</div>
                </div>
              </div>
              <div class="mZCenterBtn" @click="goInterrogationIndex1">进入</div>
            </div>
            <div></div>
          </div>
        </div>
        <!-- 民族远程专属 -->
        <!-- v-if="Services.ClientId == 'AssociationWeb'" -->
        <div class="fast-nav" style="margin-bottom: 0; padding-bottom: 0; border-bottom-right-radius: 0px; border-bottom-left-radius: 0px" v-if="Services.ClientId == 'AssociationWeb'">
          <div class="fastNavBox">民族医药</div>
          <div class="mzSearchBox">
            <div class="mzListBox" @click="goMznext(1)">
              <img class="mzListBoxImg" src="../assets/img/menuLogo/mztb01.png" />
              <div class="" style="font-size: 16px; color: #333333; margin-left: 12px">疾病查询</div>
            </div>
            <div class="mzListBox" @click="goMznext(2)">
              <img class="mzListBoxImg" src="../assets/img/menuLogo/mztb02.png" />
              <div class="" style="font-size: 16px; color: #333333; margin-left: 12px">中成药查询</div>
            </div>
            <div class="mzListBox" @click="goMznext(3)">
              <img class="mzListBoxImg" src="../assets/img/menuLogo/mztb03.png" />
              <div class="" style="font-size: 16px; color: #333333; margin-left: 12px">方剂查询</div>
            </div>
            <div class="mzListBox" @click="goMznext(4)">
              <img class="mzListBoxImg" src="../assets/img/menuLogo/mztb04.png" />
              <div class="" style="font-size: 16px; color: #333333; margin-left: 12px">中药材查询</div>
            </div>
            <div class="mzListBox1" @click="goMznext(5)">
              <img class="mzListBoxImg" src="../assets/img/menuLogo/mztb05.png" />
              <div class="" style="font-size: 16px; color: #333333; margin-left: 12px">西药查询</div>
            </div>
          </div>
        </div>
        <div class="fast-nav">
          <div class="fastNavBox">医学智库</div>
          <ul class="clearfix">
            <li :class="item.type == 1 ? 'licolor1' : item.type == 2 ? 'licolor2' : 'licolor0'" v-for="(item, index) in indexModules" :key="index" @click="gonewUrl(item)" style="cursor: pointer">
              <div class="nav-img">
                <img :src="item.img" alt="" />
              </div>
              <p>{{ item.name }}</p>
            </li>
          </ul>
        </div>
      </el-main>
      <el-footer>
        <footer>
          <ul class="footer-box clearfix flex-center align-s">
            <!-- <li>
                            <div class="footer-logo1">
                                <img v-if="orgimgkeyPathData2" :src=" orgimgkeyPathData2.orgimgkeyPath2" alt="" />
                                <img v-else src="../assets/img/logo.png" alt="" />
                            </div>
                            <p class="comp-mes">
                                河南千越医疗科技有限公司是专门从事数字健康与医疗信息化技术产品研发与推广的高科技企业，主要致力于自有知识产权的应用型软件研发、销售和服务，是中国数字化全医学检索的领航者，通过《基层医疗电子化检索系统》来推动中国医疗改革的发展。
                            </p>
                            <p class="comp-info">
                                <router-link to="/company/company">公司介绍<i class="el-icon-arrow-right"></i><i class="el-icon-arrow-right"></i></router-link>
                            </p>
                        </li>
                        <li style="width: 250px">
                            <div class="foot-tit">
                                <h3>相关 <span>链接</span></h3>
                                <span class="tit-lin"></span>
                            </div>
                            <dl class="foot-nav clearfix">
                                <dt>
                                    <router-link to="/index">首页</router-link>
                                </dt>
                                <dt>
                                    <router-link to="/company/company">公司介绍</router-link>
                                </dt>
                                <dt>
                                    <router-link to="/company/contactUs">联系我们</router-link>
                                </dt>
                                <dt><a target="_blank" href="/20210712091219.pdf">使用帮助</a></dt>
                                <dt>
                                    <router-link to="/company/disclaimer">隐私政策</router-link>
                                </dt>
                                <dt>
                                    <router-link to="/company/UserAgreement">服务协议</router-link>
                                </dt>
                            </dl>
                        </li> -->
            <!-- <li style="width: 250px">
                            <div class="foot-tit">
                                <h3>联系 <span>我们</span></h3>
                                <span class="tit-lin"></span>
                            </div>
                            <dl class="foot-us">
                                <dt><div class="icon"><img src="../assets/img/tel_icon.png" alt=""></div><p>0395-3393819（平台电话）</p></dt>
                                <dt><div class="icon"><img src="../assets/img/tel_icon.png" alt=""></div><p>15603952828（周总）</p></dt>
                                <dt><div class="icon"><img src="../assets/img/tel_icon.png" alt=""></div><p>18639512888（陈总）</p></dt>
                                <dt>
                                    <div class="icon"><img src="../assets/img/web_icon.png" alt=""></div>
                                    <p>Http://www.qyyl365.com</p>
                                </dt>
                                <dt>
                                    <div class="icon"><img src="../assets/img/adr_icon.png" alt=""></div>
                                    <p>河南省-漯河市</p>
                                </dt>
                            </dl>
                        </li> -->
            <!-- <li class="foot-us" style="width: 410px">
                            <div class="foot-tit">
                                <h3>关注 <span>我们</span></h3>
                                <span class="tit-lin"></span>
                            </div>
                            <div class="qrcode clearfix">
                                <div class="left">
                                    <img src="../assets/img/qrcode.png" alt="">
                                    <p>关注公众号</p>
                                </div>
                                <div class="left">
                                    <img src="../assets/img/ampCode.jpg" alt="">
                                    <p>微信小程序</p>
                                </div>
                                <div class="left">
                                    <img src="../assets/img/code_d3.png" alt="">
                                    <p>APP下载(安卓)</p>
                                </div>
                            </div>

                        </li> -->
          </ul>
          <div class="copyright">
            <p class="imgSrc">
              <span class="toImg" @click="toImg(4)">互联网药品许可证</span><span class="line">|</span>
              <span class="toImg" @click="toImg(2)">增值电信业务经营许可证</span>
              <span class="line">|</span>
              <span class="toImg" @click="toImg(3)">广播电视台节目制作许可证</span>
              <span class="line">|</span>
              <a href="http://ccm.mct.gov.cn:80/ccnt/sczr/service/business/emark/toDetail/de3aa6f9d69d4187b1b25e28f2a043c4" target="view_window">
                <img class="img-bottom" src="../assets/img/imgIcon.jpg" alt="" />
                <span class="toImg">网络文化经营许可证</span>
              </a>
            </p>
            <p>©2021qyyl365.com版权所有<a style="color: #808080" href="https://beian.miit.gov.cn/" target="view_window">豫ICP备2021000748号</a>.千越医疗科技有限公司提供技术支持</p>
          </div>
        </footer>
      </el-footer>
      <!--隐私政策/服务协议弹框-->
      <el-dialog title="提示" :visible.sync="agreeDialog" width="1200px" top="10vh" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="false">
        <el-tabs v-model="activeAgree">
          <el-tab-pane label="服务协议" name="user">
            <el-scrollbar style="height: 500px">
              <div class="agreement-box">
                <div class="agreement-tit">
                  <h3>服务协议</h3>
                </div>
                <div class="agreement-content">
                  <h4>一、名词</h4>
                  <p>1.平台：由河南千越医疗科技有限公司开发的所有软件、系统等产品（以下简称“本平台”）；</p>
                  <p>2.服务：由河南千越医疗科技有限公司为各角色用户提供的所有服务；</p>
                  <p>3.千越：河南千越医疗科技有限公司的简称（以下简称“千越”或“本公司”）。</p>
                  <h4>二、总则</h4>
                  <p>1.本公司根据本服务协议为个角色用户提供服务。本协议内容，可能会随着公司发展、平台发展、时长环境等因素随时更新调整，请您知晓。本协议一旦发生变动，即代替原来的服务协议并正式生效使用。</p>
                  <p>2.您在享受本公司的所有服务之前，请先仔细阅读本服务协议。如您不同意本服务协议的任何条款，请您立即停止使用本公司提供的全部服务；如您开始或继续使用本平台，视为您已了解并完全同意本服务协议各项内容。</p>
                  <h4>三、服务说明</h4>
                  <p>1.本公司提供的所有服务，除非本服务协议另有其它明示规定，均受到本服务协议之规范请您知晓并同意</p>
                  <p>2.本公司提供的所有服务，仅依其当前所呈现的状况提供，本公司保留不经事先通知为系统升级、维护或其它目的暂停提供服务任何部分的权利。</p>
                  <p>3.本公司为您提供的帐号、密码在使用本平台过程中，任何行为及其结果由您承担全部责任，请您务必知晓并同意。</p>
                  <p>4.您了解并同意，遵守中华人民共和国相关法律、法规的所有规定，如您的行为违反国家法律和法规的任何规定，有可能构成犯罪的，将被追究刑事责任，并由您承担全部法律责任。</p>
                  <p>5.如果本公司有理由认为您的任何行为违反或可能违反国家法律和法规的任何规定，包括但不限于您的任何言论和其它行为，本公司可在任何时候不经任何事先通知终止向您提供服务。</p>
                  <h4>四、注册义务</h4>
                  <p>为了能安全、快捷地享受本公司提供的所有服务，请您确保在本平台上填写真实、有效、合法的资料。若您提供任何违法、违反道德或本公司认为不适合在平台上展示的内容，本公司有权暂停或终止您的帐号，并拒绝您现在和未来提供的全部或任何部分服务。</p>
                  <p>本公司无须对任何用户的任何登记资料承担任何责任，包括但不限于鉴别、核实任何登记资料的真实性、正确性、完整性、适用性及/或是否为最新资料的责任。</p>
                  <h4>五、用户信息及安全条款</h4>
                  <p>您使用本平台过程中，应对所有使用您的密码及帐号的行为负完全的责任。请您知晓并同意：</p>
                  <p>1.当您的帐号信息发生其它任何安全问题或异常时，请您立即通知本公司客服，因您未能及时上报问题而造成的损失，本公司不需承担任何责任；</p>
                  <p>2.因您未保管好自己的帐号和密码等信息而产生的任何损失或损害，本公司无法也不承担任何责任；</p>
                  <p>3.您要对自己帐号中的所有行为和事件负全责。因您未保管好自己的帐号和密码而对您自身、本公司或任何第三方造成的损害，您将负全部责任。</p>
                  <h4>六、隐私权条款</h4>
                  <p>1.请你知晓，您提供的登记资料及本公司保留的有关您的若干其它资料将受到中国有关的法律、法规和本公司《隐私声明》之规范。</p>
                  <p>2.根据有关法律法规规定，请您知晓并同意，任何经由本平台而发布、上传的文字、资讯、资料、照片、图形、视讯、信息或其它资料（以下简称“内容 ”），无论系公开还是私下传送，均由内容提供者承担责任。牛文文仅为用户提供内容存储空间，无法控制经由本平台传送之内容，因此不保证内容的正确性、完整性或品质。</p>
                  <p>3.您发布、上传的可能会令人不快、不适当或令人厌恶之内容，本公司有权依法停止传输任何前述内容，并采取相应行动，包括但不限于暂停您的全部或部分服务。</p>
                  <p>4.您发布、上传的任何前述内容，本公司认为有违反中华人民共和国相关法律法规、违反道德及本平台相关规定的，本公司有权保存有关记录，并向有关机关报告。</p>
                  <h4>七、用户行为</h4>
                  <p>请您知晓并同意，您不会在任何时候、任何情况下利用本平台进行任何违法或不正当的活动，包括但不限于下列行为∶</p>
                  <p>1.发布或以其它方式传送含有下列内容之一的信息：</p>
                  <p>违反中华人民共和国宪法所确定的基本原则的；</p>
                  <p>危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的；</p>
                  <p>损害国家荣誉和利益的；</p>
                  <p>煽动民族仇恨、民族歧视、破坏民族团结的；</p>
                  <p>破坏国家宗教政策，宣扬邪教和封建迷信的；</p>
                  <p>散布谣言，扰乱社会秩序，破坏社会稳定的；</p>
                  <p>散布淫秽、色情、赌博、暴力、凶杀、恐怖或者教唆犯罪的；</p>
                  <p>侮辱或者诽谤他人，侵害他人合法权利的；</p>
                  <p>含有虚假、诈骗、有害、胁迫、侵害他人隐私、骚扰、侵害、中伤、粗俗、猥亵、或其它道德上令人反感的内容；</p>
                  <p>含有中国法律、法规、规章、条例以及任何具有法律效力之规范所限制或禁止的其它内容的；</p>
                  <p>含有本公司认为不适合在平台展示的任何形式的内容；</p>
                  <p>2.以任何方式危害个人、单位的合法权益；</p>
                  <p>3.将侵害他人著作权、专利权、商标权、商业秘密、或其它专属权利的内容，通过任何形式进行发布或以其它方式传送；</p>
                  <p>4.将设计目的在于干扰、破坏或限制任何计算机软件、硬件或通讯设备功能之计算机病毒（包括但不限于木马程序（trojan horses）、蠕虫（worms）、定时炸弹、删除蝇（cancelbots）（以下简称“病毒”）或其它计算机代码、档案和程序之任何资料，加以发布、发送或以其它方式传送；</p>
                  <p>5.干扰或破坏本服务或与本服务相连线之服务器和网络，或违反任何关于本服务连线网络之规定、程序、政策或规范；</p>
                  <p>6.跟踪、人肉搜索或以其它方式骚扰他人；</p>
                  <p>7.故意或非故意地违反任何适用的当地、国家法律，以及任何具有法律效力的规则；</p>
                  <p>8.未经合法授权而截获、篡改、收集、储存或删除他人个人信息、站内邮件或其它数据资料，或将获知的此类资料用于任何非法或不正当目的。</p>
                  <p>9.您知晓并同意，本公司为了保证用户利益，符合法律法规等相关规定，包括但不限于以下情况，本公司有权且在合理、必要的范围内，将内容加以保存或揭露：</p>
                  <p>遵守法律法规相关规定、程序及准则；</p>
                  <p>执行本服务协议相关规定；</p>
                  <p>回应任何第三方机构或者第三人提出的权利主张；</p>
                  <p>保护本公司、其本平台用户及公众的合法权益，保证财产或个人安全；</p>
                  <h4>八、服务协议修改</h4>
                  <p>本公司有权于任何时间暂时或修改或终止本服务协议的任何部分内容。请你知晓并同意对于本服务协议所作的任何修改、暂停或终止，本公司对您、任何第三人或机构均无需承担任何责任。</p>
                  <h4>九、终止服务</h4>
                  <p>您知晓并同意本公司基于包括但不限于平台运行、平台发展、平台升级等因素考虑，认为您已经违反本服务协议的使用规范及合作精神，本公司将停止或永久暂停您的账号信息，并终止为您提供的全部或部分服务，本公司也有权将您在本平台内的任何内容加以移除并删除。</p>
                  <p>您知晓并同意，您在本平台上的帐号及您帐号中所有相关信息及文件到期，而您没有及时续费，本公司将停止或永久暂停您的账号信息，并终止为您提供的全部或部分服务，本公司也有权将您在本平台内的任何内容加以移除并删除，本公司对您或任何第三人均不承担任何责任。</p>
                </div>
              </div>
            </el-scrollbar>
          </el-tab-pane>
          <el-tab-pane label="隐私政策" name="dis">
            <el-scrollbar style="height: 500px">
              <div class="agreement-box">
                <div class="agreement-tit">
                  <h3>隐私政策</h3>
                </div>
                <div class="agreement-content">
                  <p>本声明适用于河南千越医疗科技有限公司（以下简称“千越”或本公司）研发的所有平台软件（以下简称“平台”）。</p>
                  <p>1、您使用系统的过程中，如果对本系统存在任何质疑，或不同意声明中的任何条款，当立刻停止使用本平台。如果您继续使用本平台，则表示您同意本声明中的所有条款。</p>
                  <p>2、当您在使用本平台的过程中，由以下几种情况给您造成的损失，本公司不予承担任何责任：</p>
                  <p class="s-p">(1)您通过非法渠道或方式，获取本平台的使用、服务等权利；</p>
                  <p class="s-p">(2)因爲您的个人原因，造成账号和密码泄露，从而造成的任何损失；</p>
                  <p class="s-p">(3)因爲您的操作不当或失误操作，造成的数据丢失、账号被盗等损失；</p>
                  <p class="s-p">(4)因您个人或者您工作单位的电脑软件、系统、硬件、通讯、其它设备等损坏而造成损失；</p>
                  <p class="s-p">(5)因您的网络安全而造成的损失，包括但不限于病毒、木马、黑客攻击或其它恶意破坏；</p>
                  <p class="s-p">(6)基础运营商原因，包括但不限于电信部门技术调整、电信/电力线路被他人破坏、电信/电力部门对电信网络/电力资源进行安装、改造、维护；</p>
                  <p class="s-p">(7)因不可抗力而造成的损失，包括但不限于自然灾害、罢工、动乱、政府行为、政策调整、法律法规调整；</p>
                  <p class="s-p">(8)其他本公司无法控制或合理预见的情形。</p>
                  <p>3、本公司根据市场环境、公司发展、产品升级等因素，会不定时对本声明进行调整、修改，如果您不同意修改或调整内容，可立刻停止使用本系统，并及时与本公司客服人员联系。如果您继续访问或使用本平台，则视为您已经接受本声明的修改或调整。</p>
                  <p>4、本公司提供的平台、软件、服务等内容，将尽最大努力确保连贯性和安全性，但本公司不能保证本平台及本公司所提供的服务毫无瑕疵，且对可能存在的瑕疵不承担责任。</p>
                  <p>5、本平台上所有的医学内容、资料、数据等仅供辅助参考, 不能也不应作为诊断及治疗的最终依据，最终的诊疗结果由使用本平台的医护人员作出判断，使用人或接受服务的人对使用本公司软件的后果承担风险，因此而发生的任何纠纷、赔偿、投诉、处罚、处分与本公司无关，本公司不承担任何责任。</p>
                  <p>6、本公司对所有平台、软件等产品拥有著作权、商标权等知识产权，受相关法律、法规的保护。任何单位或个人不得在没有本公司授权的情况下对本公司开发的任何产品进行任何非法操作，包括但不限于反编译、二次开发、功能修改等。</p>
                  <p>7、为了提供更加完善的服务，本公司有权定期或不定期地对服务平台或相关设备进行升级维护，包括但不限于检修、维护、升级等，届时本平台将提前12小时以平台消息通知的方式告知您，如因升级维护而造成本平台在合理时间内中断或暂停的，本公司不承担责任。</p>
                  <p>8、如因网络状况、通讯线路等任何技术原因而导致升级维护失败、暂停等情况，本公司承诺将全力尽快处理问题，整个升级维护过程中，因您的不配合而造成的损失，本公司不承担责任。</p>
                  <p>9、本声明未涉及的问题请参见国家有关法律法规，当本声明与国家有关法律法规冲突时，以国家法律法规为准。</p>
                  <br />
                  <br />
                  <p>特此声明</p>
                  <br />
                  <p style="text-align: right">声明人：河南千越医疗科技有限公司</p>
                </div>
              </div>
            </el-scrollbar>
          </el-tab-pane>
        </el-tabs>
        <span slot="footer" class="dialog-footer">
          <!--                <el-button @click="agreeDialog = false">取 消</el-button>-->
          <el-button type="primary" @click="setAgreeDialog" :disabled="disabled">{{ agreeBtn }}</el-button>
          <br />
          <el-checkbox v-model="setDialog" label="不再提示"></el-checkbox>
        </span>
      </el-dialog>
    </el-container>
    <!-- 资质认证  // 折腾代码无意义-->
    <el-dialog title="资质认证" :visible.sync="certificateAuditVisible" width="40%">
      <div class="AuditVisibleBox">
        <div class="AuditVisibleDes" v-if="GetCertificateState == 2">
          拒绝原因：<span style="color: #ff0000">{{ GetCertificateState.remark }}</span>
        </div>
        <div class="AuditVisibleTitle" style="margin-top: 0px">身份证人像面<span style="color: #ff0000">*</span></div>
        <div class="AuditVisibleDes">1.请上传<span style="color: #ff0000">身份证人像面</span>完整、清晰的照片;</div>
        <el-upload :action="FileUploadUrl" :headers="headers" :limit="1" list-type="picture-card" :on-preview="handlePictureCardPreview1" :on-success="handleAvatarSuccess1">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible1">
          <img width="100%" :src="dialogImageUrl1" alt="" />
        </el-dialog>
      </div>
      <div class="AuditVisibleBox">
        <div class="AuditVisibleTitle">身份证国徽面<span style="color: #ff0000">*</span></div>
        <div class="AuditVisibleDes">1.请上传<span style="color: #ff0000">身份证国徽面</span>完整、清晰的照片;</div>
        <el-upload :action="FileUploadUrl" :headers="headers" :limit="1" list-type="picture-card" :on-preview="handlePictureCardPreview2" :on-success="handleAvatarSuccess2">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible2">
          <img width="100%" :src="dialogImageUrl2" alt="" />
        </el-dialog>
      </div>
      <div class="AuditVisibleBox">
        <div class="AuditVisibleTitle">资质认证<span style="color: #ff0000">*</span></div>
        <div class="AuditVisibleDes">1.请上传相关的<span style="color: #ff0000">执业证书国徽页、个人照片页、个人信息页 </span>完整、清晰的照片，最多5张；</div>
        <el-upload :action="FileUploadUrl" :headers="headers" :limit="5" multiple list-type="picture-card" :on-preview="handlePictureCardPreview3" :on-success="handleAvatarSuccess3">
          <i class="el-icon-plus"></i>
        </el-upload>
        <el-dialog :visible.sync="dialogVisible3">
          <img width="100%" :src="dialogImageUrl3" alt="" />
        </el-dialog>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certificateAuditVisible = false">取 消</el-button>
        <el-button type="primary" @click="addCertificateAuditVisible">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import touch from "../components/touch";
import nhCom from "../components/navheader";
import storage from "../utils/customstorage";
import { loginOut } from "../utils/userInfo";
import { Menus } from "../components/Menu/Menu";
import { UserInfo } from "../components/User/UserInfo";
import { getDrugOrHerbal } from "../utils/diseaseUtility";
import ftCom from "../components/footer";
// import rtBar from "../components/right"
import { MessageDomain } from "../components/personal/Message";
import { patientMain } from "../components/patienTmanagement/patienTmanagement";
export default {
  name: "index",
  inject: ["reload"],
  computed: {
    headers() {
      return {
        Authorization: "Bearer" + " " + this.asstoken,
      };
    },
  },
  components: {
    ftCom,
    nhCom,
    // rtBar,
    touch,
  },
  data() {
    var isSpeaking = false;
    var menu = new Menus(this.TokenClient, this.Services.Authorization);
    var user = new UserInfo(this.TokenClient, this.Services.Authorization);
    var MessageDomainTemp = new MessageDomain(this.TokenClient, this.Services.Authorization);
    var patientMainTemp = new patientMain(this.TokenClient, this.Services.Authorization);
    var crowdOptions = menu.GetIndexCrowdOptions();
    var indexModules = menu.GetIndexModules();
    var GetIndexModulesT = menu.GetIndexModulesT();
    return {
      indexFrom: {
        input: "",
        checkAll: true,
        checkedCrowd: crowdOptions,
        crowds: crowdOptions,
        isIndeterminate: false,
      },
      userId: this.$store.state.localData.userInfo.id,
      userName: this.$store.state.localData.userInfo.name,
      userhead: this.$store.state.localData.userInfo.photo,
      userInfoData: this.$store.state.localData.userInfo,
      patientMainDomain: patientMainTemp,
      crowdOptions: crowdOptions,
      indexModules: indexModules,
      GetIndexModulesT: GetIndexModulesT,
      userDomain: user,
      activeAgree: "user",
      agreeDialog: false,
      false: false,
      setDialog: false,
      disabled: true,
      agreeBtn: "10秒后可关闭",
      orgimgkeyPathData1: "",
      orgimgkeyPathData2: "",
      Result: MessageDomainTemp,
      isSpeaking: false,
      // 折腾代码无意义
      userType: this.$store.state.localData.userInfo.detailUserType,
      personalData: {},
      GetCertificateState: 0,
      certificateAuditVisible: false,
      FileUploadUrl: this.Services.Statics + "/api/upload",
      asstoken: "",
      idCard1: "",
      idCard2: "",
      idCard3: [],
      dialogImageUrl1: "",
      dialogImageUrl2: "",
      dialogImageUrl3: "",
      dialogVisible3: false,
      dialogVisible2: false,
      dialogVisible1: false,
      //折腾代码结束随时删除
    };
  },
  async created() {
    if (this.$store.state.localData.drugNames.length == 0 || this.$store.state.localData.herbalNames.length == 0) {
    }
    this.asstoken = await this.TokenClient.AccessToken();
    this.ShowDialog();
    this.getorgLogoDialog();
    this.getorgLogoDialog1();
    this.getHomeMessage();
    this.LoadUserInfo();
  },
  mounted() {
    console.log(this.crowdOptions)
    // this.AudioBo()
  },
  methods: {
    // 代码111111111111
    goMznext(e) {
      if (e == 1) {
        this.$router.push({
          path: "/mzList/mzdiseaseBank",
        });
      }
      if (e == 2) {
        this.$router.push({
          path: "/mzList/mzDrugBank",
        });
      }
      if (e == 3) {
        this.$router.push({
          path: "/mzList/mzHerbal",
        });
      }
      if (e == 4) {
        this.$router.push({
          path: "/mzList/mzMaterial",
        });
      }
      if (e == 5) {
        this.$router.push({
          path: "/mzList/mzDrugBank",
          query: {
            typeName: "xi",
          },
        });
      }
    },
    addCertificateAuditVisible() {
      let _this = this;
      if (_this.idCard1 == "") {
        _this.$message({
          message: "请上传身份证人像面",
          type: "error",
        });
        return;
      }
      if (_this.idCard2 == "") {
        _this.$message({
          message: "请上传身份证国徽面",
          type: "error",
        });
        return;
      }
      if (_this.idCard3.length == 0) {
        _this.$message({
          message: "请上传资质认证",
          type: "error",
        });
        return;
      }
      let params = {
        idCardFront: _this.idCard1,
        idCardBack: _this.idCard2,
        certificateImage: _this.idCard3.toString(),
      };
      _this.patientMainDomain.GetUploadUserCertificateAudit(
        params,
        function (data) {
          console.log(data.data);
          _this.$message({
            message: "提交审核成功",
            type: "success",
          });
          _this.certificateAuditVisible = false;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    handlePictureCardPreview1(file) {
      this.dialogImageUrl1 = file.url;
      this.dialogVisible1 = true;
    },
    handlePictureCardPreview2(file) {
      this.dialogImageUrl2 = file.url;
      this.dialogVisible2 = true;
    },
    handlePictureCardPreview3(file) {
      this.dialogImageUrl3 = file.url;
      this.dialogVisible3 = true;
    },
    handleAvatarSuccess1(res) {
      this.idCard1 = res[0].completeFilePath;
    },
    handleAvatarSuccess2(res) {
      this.idCard2 = res[0].completeFilePath;
    },
    handleAvatarSuccess3(res) {
      this.idCard3.push(res[0].completeFilePath);
    },
    goInterrogationIndex1() {
      var _this = this;
      if (_this.personalData.organizationId) {
        _this.goUploadUserCertificateAudit();
      }
    },
    goUploadUserCertificateAudit() {
      let _this = this;
      if (_this.Services.ClientId == "TotalWeb") {
        let url = _this.$router.resolve({
          name: "interrogationIndex",
        });
        window.open(url.href, "_blank");
        return;
      }
      _this.idCard3 = [];
      // -1:未上传  0待审核，1 审核通过，2 审核未通过
      _this.patientMainDomain.GetCertificate(
        function (data) {
          _this.GetCertificateState = data.data;
          if (data.data.ifUpload == 1) {
            if (_this.personalData.isPharmacist == 1) {
              let url = _this.$router.resolve({
                name: "drugPremangePatientList",
              });
              window.open(url.href, "_blank");
            } else {
              let url = _this.$router.resolve({
                name: "interrogationIndex",
              });
              window.open(url.href, "_blank");
            }
          } else if (data.data.ifUpload == -1) {
            _this
              .$confirm("您好，您的账户未上传有效身份证件及资质证书,请在线上传所需资料，您也可以联系本机构管理员进行操作!过时将影响您部分功能的使用，请您知晓并理解!", "提示", {
                confirmButtonText: "立刻上传",
                cancelButtonText: "暂不上传,继续使用",
                type: "warning",
              })
              .then(() => {
                _this.certificateAuditVisible = true;
              })
              .catch(() => {
                if (_this.personalData.isPharmacist == 1) {
                  let url = _this.$router.resolve({
                    name: "drugPremangePatientList",
                  });
                  window.open(url.href, "_blank");
                } else {
                  let url = _this.$router.resolve({
                    name: "interrogationIndex",
                  });
                  window.open(url.href, "_blank");
                }
              });
          } else if (data.data.ifUpload == 0) {
            _this.$message({
              message: "正在审核中...",
              type: "warning",
            });
            if (_this.personalData.isPharmacist == 1) {
              let url = _this.$router.resolve({
                name: "drugPremangePatientList",
              });
              window.open(url.href, "_blank");
            } else {
              let url = _this.$router.resolve({
                name: "interrogationIndex",
              });
              window.open(url.href, "_blank");
            }
          } else if (data.data.ifUpload == 2) {
            _this
              .$confirm("拒绝理由:" + data.data.remark, "提示", {
                confirmButtonText: "立刻上传",
                cancelButtonText: "暂不上传,继续使用",
                type: "warning",
              })
              .then(() => {
                _this.certificateAuditVisible = true;
              })
              .catch(() => {
                if (_this.personalData.isPharmacist == 1) {
                  let url = _this.$router.resolve({
                    name: "drugPremangePatientList",
                  });
                  window.open(url.href, "_blank");
                } else {
                  let url = _this.$router.resolve({
                    name: "interrogationIndex",
                  });
                  window.open(url.href, "_blank");
                }
              });
            // _this.certificateAuditVisible = true
          } else {
            // 其他
          }
        },
        function (error) {
          console.log(error);
        }
      );
      // this.certificateAuditVisible = true
    },
    LoadUserInfo() {
      var _this = this;
      _this.userDomain.UserMes(
        function (data) {
          _this.personalData = data.data;
          sessionStorage.setItem("userInfo", JSON.stringify(data.data));
        },
        function (error) {}
      );
    },
    // 代码111111111111结束 无聊代码来回搬
    getHomeMessage() {
      let _this = this;
      _this.Result.GetHomeMessage(
        function (data) {
          let readNumber = 0;
          for (let i = 0; i < data.data.length; i++) {
            readNumber = readNumber + data.data[i].unreadCount;
          }
          if (readNumber > 0) {
            _this.$store.commit("messageData", true);
          } else {
            _this.$store.commit("messageData", false);
          }
        },
        function (error) {
          console.log(error);
        }
      );
    },
    gonewUrl(item) {
      this.$router.push({
        path: item.url,
      });
    },
    toImg(type) {
      this.$router.push({
        path: "/imgShow",
        query: {
          type: type,
        },
      });
    },
    ShowDialog() {
      var _this = this;
      var firstLogin = sessionStorage.getItem("firstLogin_" + _this.userId);
      if (!firstLogin) {
        _this.userDomain.IsShowDialog(
          function (data) {
            if (data.data == 0) {
              _this.agreeDialog = false;
            } else if (data.data == 1) {
              _this.agreeDialog = true;
              _this.agreeChecked();
            }
          },
          function (error) {
            console.log(error);
          }
        );
      }
    },
    getorgLogoDialog() {
      var _this = this;
      _this.userDomain.orgLogoDialog(
        this.$store.state.localData.userInfo.organizationId,
        "0",
        "fff",
        "%20",
        "1",
        function (data) {
          if (data.data.results.length > 0) {
            let params = {
              orgimgkey1: "fff",
              orgimgkeyPath1: _this.Services.ossUpload + data.data.results[0].logoPath,
            };
            localStorage.setItem("orgimgData1", JSON.stringify(params));
            _this.orgimgkeyPathData1 = JSON.parse(localStorage.getItem("orgimgData1"));
          } else {
            _this.orgimgkeyPathData1 = "";
          }
        },
        function (error) {
          _this.orgimgkeyPathData1 = "";
        }
      );
    },
    getorgLogoDialog1() {
      var _this = this;
      _this.userDomain.orgLogoDialog(
        this.$store.state.localData.userInfo.organizationId,
        "0",
        "000",
        "%20",
        "1",
        function (data) {
          if (data.data.results.length > 0) {
            let params = {
              orgimgkey2: "fff",
              orgimgkeyPath2: _this.Services.ossUpload + data.data.results[0].logoPath,
            };
            localStorage.setItem("orgimgData2", JSON.stringify(params));
            _this.orgimgkeyPathData2 = JSON.parse(localStorage.getItem("orgimgData2"));
          } else {
            _this.orgimgkeyPathData2 = "";
          }
        },
        function (error) {
          console.log(error);
          _this.orgimgkeyPathData2 = "";
        }
      );
    },
    setAgreeDialog() {
      var _this = this;
      sessionStorage.setItem("firstLogin_" + _this.userId, false);
      if (_this.setDialog == true) {
        _this.userDomain.setDialog(
          function (data) {},
          function (error) {
            console.log(error);
          }
        );
      }
      _this.agreeDialog = false;
    },
    agreeChecked() {
      this.disabled = true;
      this.agreeBtn = "5秒后可关闭";
      let time = 5;
      let timer = setInterval(() => {
        if (time == 0) {
          clearInterval(timer);
          this.disabled = false;
          this.agreeBtn = "已阅读以上内容";
        } else {
          this.agreeBtn = time + "秒后可关闭";
          this.disabled = true;
          time--;
        }
      }, 1000);
    },
    handleCheckAllChange(val) {
      this.indexFrom.checkedCrowd = val ? this.crowdOptions : [];
      this.indexFrom.isIndeterminate = false;
    },
    handleCheckedCrowdChange(val) {
      let checkedCount = val.length;
      this.indexFrom.checkAll = checkedCount === this.indexFrom.crowds.length;
      this.indexFrom.isIndeterminate = checkedCount > 0 && checkedCount < this.indexFrom.crowds.length;
    },
    //首页搜索
    submitSearch: function () {
      if (this.indexFrom.input == "") {
        this.$message({
          type: "warning",
          message: "搜索内容不能为空",
        });
        return false;
      }
      if (this.indexFrom.checkedCrowd == "") {
        this.$message({
          type: "warning",
          message: "选项不能为空",
        });
        return false;
      }
      {
        //跳转到详情
        this.$router.push({
          path: "/searchList",
          //name:'foodDetail',
          query: {
            activeName: this.indexFrom.checkedCrowd.slice(0, 1).join(","),
            keyWord: this.indexFrom.input,
          },
        });
      }
      /*search(this.indexFrom.checkedCrowd,this.indexFrom.input)*/
    },
    SearchInputKeydown(event) {
      this.submitSearch();
    },
    out() {
      loginOut();
      sessionStorage.removeItem("userInfo");
      this.$router.push("/login");
      this.$message.success("已退出");
    },
  },
};
</script>

<style scoped>
@import "../assets/css/index.css";

.imgSrc span:hover,
a:hover {
  cursor: pointer;
  color: #1fb7a6 !important;
}
.footer-logo1 {
  width: 175px;
  height: 50px;
  margin-bottom: 10px;
}
.footer-logo1 img {
  width: 100%;
  height: 100%;
}

.imgSrc span {
  cursor: pointer;
  color: #808080 !important;
}

span.line {
  margin: 0 5px;
}

.img-bottom {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin: -8px 5px;
}

.img {
  width: 100%;
  height: 100%;
}

.copyright p {
  line-height: 30px;
  height: 30px;
}
</style>
<style>
.home .el-scrollbar__wrap {
  overflow-x: hidden;
}

.home .el-dialog__wrapper {
  background: rgba(0, 0, 0, 0.7);
}

.home .el-dialog__footer {
  text-align: center;
}

.home .el-header,
.home .el-main,
.home .el-footer {
  padding: 0;
  height: auto !important;
}

/*搜索*/
.home .el-input-group__append,
.home .el-input-group__prepend {
  padding: 0;
  border: none;
}

/*筛选*/
.home .el-checkbox-group {
  display: inline;
}

.home .el-checkbox {
  width: 25%;
  margin: 10px 0;
}

/*.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #01c2ac;
      border-color: #01c2ac;
    }
    .el-checkbox__input.is-focus .el-checkbox__inner{
      border-color: #01c2ac;
    }
    .el-checkbox__inner:hover{
      border-color: #01c2ac;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #01c2ac ;
    }*/
.home .el-checkbox__inner {
  width: 16px;
  height: 16px;
}

.home .el-checkbox__inner::after {
  height: 9px;
  left: 5px;
  width: 4px;
}

.home .el-badge__content.is-dot {
  height: 6px;
  width: 6px;
}

.home .el-badge__content.is-fixed.is-dot {
  right: 0px;
  top: 11px;
}

.home .fast-nav ul li:hover {
  box-shadow: 0px 0px 11px #d5d5d5;
  cursor: pointer;
}
.fastNavBox {
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  border-left: 6px solid #ea9232;
  padding-left: 8px;
}
.fastNavBox1 {
  font-weight: bold;
  font-size: 16px;
  color: #333333;
  border-left: 6px solid #ea9232;
  padding-left: 8px;
  width: 50%;
}
.fastNavR {
  width: 64%;
  margin: auto;
  display: flex;
  /* align-items: center; */
  padding: 0px 60px;
  justify-content: space-between;
  background-color: #ffffff;
}
.mZCenterBox {
  padding: 30px 20px;
  width: 70%;
  /* margin: 20px auto; */
  margin-top: 20px;
  background-image: url("../assets/img/mzbg.png");
  background-repeat: no-repeat;
  background-size: 100% 96%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.mZCenterBoxImg {
  width: 60px;
  height: 60px;
}
.mZCenterBtn {
  padding: 5px 20px;
  border: 1px solid #e7b986;
  border-radius: 16px;
  font-size: 16px;
  color: #ea9232;
  text-align: center;
  cursor: pointer;
}
.fast-nav2 {
  width: 100%;
}
.AuditVisibleBox {
  padding-left: 30px;
}
.AuditVisibleTitle {
  font-weight: bold;
  font-size: 18px;
  color: #333333;
  margin-top: 30px;
}
.AuditVisibleDes {
  font-size: 16px;
  color: #666666;
  margin: 15px 0;
  margin-top: 5px;
}
.mzSearchBox {
  display: flex;
  align-items: center;
}
.mzListBox1 {
  width: 15%;
  display: flex;
  align-items: center;
  padding: 17px 24px;
  background: #f2fffd;
  border-radius: 6px;
  border: 1px solid #a9efe6;
  margin-top: 20px;
}
.mzListBox {
  width: 12%;
  display: flex;
  align-items: center;
  padding: 17px 24px;
  background: #fffbf5;
  border-radius: 6px;
  border: 1px solid #f6e1c3;
  margin-top: 20px;
  margin-right: 30px;
  cursor: pointer;
}
.mzListBoxImg {
  width: 40px;
  height: 40px;
}
</style>
