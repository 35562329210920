<template>
  <div></div>
</template>

<script>
import { Menus } from "./Menu/Menu";
export default {
  props: ["fullPath", "title"],
  data() {
    var menu = new Menus(this.TokenClient, this.Services.Authorization);
    return {
      menuDomain: menu,
      menuList: [],
    };
  },
  mounted() {
    this.getMenus();
  },
  methods: {
    openTip() {
      this.$confirm("您当前版本不支持此功能，请前往升级更高版本解锁此功能！", "提示", {
        confirmButtonText: "去升级",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$router.push("/personal/upgrade");
        })
        .catch(() => {});
    },
    getMenus() {
      var _this = this;
      _this.menuDomain.GetMenu(
        1,
        function (data) {
          let keys = Object.keys(data.data)[0];
          _this.menuList = data.data[keys];
          _this.isCanUser(_this.menuList);
        },
        function (err) {
          console.log(err);
        }
      );
    },
    isCanUser(arry) {
      arry.forEach((item) => {
        if (this.fullPath == item.url || (item.url.indexOf(this.title)>-1)) {
          this.$emit("currentlyAvailable", item.currentlyAvailable);
        } else {
          if (item.children.length > 0) {
            this.isCanUser(item.children);
          }
        }
      });
    },
  },
};
</script>
