import store from "../../store/index";
let Menus = function (tokenClient, serviceAddr) {
  let TokenClient = tokenClient;
  let ServiceBaseAddress = serviceAddr;

  var searchmodules = [
    {
      id: "disease",
      name: "疾病",
      remark: "疾病库",
    },
    {
      id: "zhengzhuang",
      name: "症状",
      remark: "疾病库",
    },
    {
      id: "drug",
      name: "药品",
      remark: "药品库",
    },
    // {
    // 	id: 'drug',
    // 	name: '药品',
    // 	remark: '药品查询'
    // },
    {
      id: "material",
      name: "中药饮片",
      remark: "中药饮片",
    },
    {
      id: "food",
      name: "食疗药膳",
      remark: "食疗药膳",
    },
    {
      id: "herbal",
      name: "中药方剂",
      remark: "中药方剂",
    },
    {
      id: "meridian",
      name: "经络穴位",
      remark: "经络穴位",
    },
    {
      id: "healthyDiet",
      name: "健康饮食",
      remark: "健康饮食",
    },
  ];

  /**
   * 获取用户角色菜单数据
   * @param {any} success
   * @param {any} error
   */
  this.Menu = function (roleId, success, error) {
    var url = ServiceBaseAddress + "/api/User/Menus";
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          store.commit("localData/menus", data);
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.getVersion = function (success, error) {
    var url = ServiceBaseAddress + "/api/User/Version";
    TokenClient.Get(
      url,
      true,
      null,
      null,
      function (data) {
        if (success) {
          var jsonObj = JSON.parse(data);
          success(jsonObj);
        }
      },
      error
    );
  };
  this.GetMenu = function (roleId, success, error) {
    var menus = store.state.localData.menus;
    if (menus) {
      if (success) {
        var jsonObj = JSON.parse(menus);
        success(jsonObj);
      }
    } else {
      this.Menu(roleId, success, error);
    }
  };
  /**
   * 获取用户角色菜单列表
   */
  this.GetMenuList = function () {
    var menus = store.state.localData.menus;
    var list = [];
    if (menus) {
      var jsonObj = JSON.parse(menus);
      let keys = Object.keys(jsonObj.data)[0];

      $.each(jsonObj.data[keys], function (index, item) {
        // if (item.currentlyAvailable) {
        // list.push(item.name);
        list.push({ name: item.name, currentlyAvailable: item.currentlyAvailable });
        if (item.children) {
          $.each(item.children, function (chileIndex, chile) {
            //   if (chile.currentlyAvailable) {
            // list.push(chile.name);
            list.push({ name: chile.name, currentlyAvailable: chile.currentlyAvailable });
            //   }
          });
        }
        // }
      });
    }
    return list;
  };
  /**
   * 获取首页搜索菜单列表
   */
  this.GetIndexCrowdOptions = function () {
    var list = this.GetMenuList();
    var newOptions = [];
    $.each(searchmodules, function (index, item) {
      if (newOptions.toString().indexOf(item.name) == -1) {
        if (list.some((x) => x.name === item.remark)) {
          newOptions.push(item.name);
          if (item.remark == "疾病库") {
            newOptions.push("症状");
          }
        }
      }
    });
    return newOptions;
  };
  // 智能辩证
  this.GetIndexModulesT = function () {
    var list = this.GetMenuList();
    var modules = [
      {
        name: "辨病论治",
        url: "/diseaseConsultation/diseasePage",
        img: require("../../assets/img/menuLogo/ncbb01.png"),
        remark: "辨病论治",
      },
      {
        name: "辨证论治",
        url: "/zdrugconsultation/zDrugPage",
        img: require("../../assets/img/menuLogo/ncbb02.png"),
        remark: "辨证论治",
        type: 1,
      },
      {
        name: "虚证辨证",
        url: "/company/startTest?titleName=中医虚证",
        img: require("../../assets/img/menuLogo/ncbb03.png"),
        remark: "虚证辨证",
      },
      {
        name: "体质辨识",
        url: "/company/startTest?titleName=中医体质",
        img: require("../../assets/img/menuLogo/ncbb04.png"),
        remark: "体质辨识",
      },
    ];
    // var result = [];
    // $.each(modules, function (index,item) {
    // 	if (list.some(x=>x.indexOf(item.remark) != '-1')) {
    // 		result.push(item);
    // 	}
    // })
    // return result;
    return modules;
  };
  /**
   * 获取首页模块列表
   */
  this.GetIndexModules = function () {
    var list = this.GetMenuList();
    var modules = [
      // {
      // 	name: '疾病查询', url: '/disease/diseaseBank', img: require('../../assets/img/nav-img1.jpg'), remark:'疾病库'
      // },
      // {
      // 	name: '药品查询', url: '/Drug/drugBank', img: require('../../assets/img/nav-img5.jpg'), remark: '药品查询'
      // },
      // {
      // 	name: '中药方剂', url: '/Drug/Herbal', img: require('../../assets/img/nav-img3.jpg'), remark: '中药方剂'
      // },
      // {
      // 	name: '中药饮片', url: '/Drug/Material', img: require('../../assets/img/nav-img2.jpg'), remark: '中药饮片'
      // },
      // {
      // 	name: '食疗药膳', url: '/Drug/Food', img: require('../../assets/img/nav-img4.jpg'), remark: '食疗药膳'
      // },
      // {
      // 	name: '中医诊断', url: '/diagnose/tcmDiagnosis', img: require('../../assets/img/nav-img6.jpg'), remark: '中医诊断'
      // },
      // {
      // 	name: '症状诊断', url: '/diagnose/Diagnose', img: require('../../assets/img/nav-img9.jpg'), remark: '症状诊断'
      // },
      // {
      // 	name: '配伍', url: '/Compatibility', img: require('../../assets/img/nav-img8.jpg'), remark: '配伍'
      // },
      // {
      // 	name: '法律法规', url: '/legislation/Legislation', img: require('../../assets/img/nav-img7.jpg'), remark: '法律法规'
      // },
      // {
      // 	name: '经络穴位', url: '/disease/MeridianBank', img: require('../../assets/img/nav-img10.jpg'), remark: '经络穴位'
      // },
      // {
      // 	name: '健康饮食', url: '/Drug/HealthyDiet', img: require('../../assets/img/nav-img4.jpg'), remark: '健康饮食'
      // }
      {
        name: "疾病查询",
        url: "/disease/diseaseBank",
        img: require("../../assets/img/menuLogo/ncbb09.png"),
        remark: "疾病库",
      },
      {
        name: "中医诊断",
        url: "/diagnose/tcmDiagnosis",
        img: require("../../assets/img/menuLogo/ncbb10.png"),
        remark: "中医诊断",
      },
      {
        name: "经络穴位",
        url: "/disease/MeridianBank",
        img: require("../../assets/img/menuLogo/ncbb11.png"),
        remark: "经络穴位",
      },
      {
        name: "配伍",
        url: "/Compatibility",
        img: require("../../assets/img/menuLogo/ncbb05.png"),
        remark: "配伍",
      },
      {
        name: "中药方剂",
        url: "/Drug/Herbal",
        img: require("../../assets/img/menuLogo/ncbb08.png"),
        remark: "中药方剂",
      },
      {
        name: "中药饮片",
        url: "/Drug/Material",
        img: require("../../assets/img/menuLogo/ncbb06.png"),
        remark: "中药饮片",
      },
      {
        name: "食疗药膳",
        url: "/Drug/Food",
        img: require("../../assets/img/menuLogo/ncbb13.png"),
        remark: "食疗药膳",
      },

      {
        name: "健康饮食",
        url: "/Drug/HealthyDiet",
        img: require("../../assets/img/menuLogo/ncbb12.png"),
        remark: "健康饮食",
      },

      {
        name: "症状诊断",
        url: "/diagnose/Diagnose",
        img: require("../../assets/img/menuLogo/ncbb19.png"),
        remark: "症状诊断",
        type: 1,
      },
      {
        name: "药品查询",
        url: "/Drug/drugBank",
        img: require("../../assets/img/menuLogo/ncbb18.png"),
        remark: "药品查询",
        type: 1,
      },
      {
        name: "法律法规",
        url: "/legislation/Legislation",
        img: require("../../assets/img/menuLogo/ncbb21.png"),
        remark: "法律法规",
        type: 2,
      },
    ];
    // var result = [];
    // $.each(modules, function (index,item) {
    // 	if (list.some(x=>x ===item.remark)) {
    // 		result.push(item);
    // 	}
    // })
    var typeList = [];
    $.each(searchmodules, function (index, item) {
      if (list.some((x) => x.name === item.remark)) {
        typeList.push(item.id);
      }
    });
    store.commit("localData/typeList", typeList);
    // return result;
    return modules;
  };

  this.GetSearchModules = function () {
    var list = this.GetMenuList();
    var searchList = [
      {
        id: "all",
        name: "全站",
      },
    ];
    $.each(searchmodules, function (index, item) {
      if (list.some((x) => x.name === item.remark)) {
        searchList.push(item);
      }
    });
    return searchList;
  };
  this.GetSearchModulesNew = function () {
    var list = this.GetMenuList();
    var searchList = [
      {
        id: "all",
        name: "全站",
        currentlyAvailable:true
      },
    ];
    // $.each(searchmodules, function (index, item) {
    //   if (list.some((x) => x.name === item.remark)) {
    //     searchList.push(item);
    //   }
    // });
    $.each(searchmodules, function (index, item) {
      let resList = list.filter((x) => x.name == item.remark);
      if (resList.length > 0) {
        searchList.push({ ...item, currentlyAvailable: resList[0].currentlyAvailable });
      }
    });
    return searchList;
  };
};

export { Menus };
